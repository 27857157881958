import { Controller, Application } from "stimulus"
const application = Application.start()

application.register("track", class extends Controller {
  static targets = ["video"]

  connect() {
    console.log('connected', this.element)
  }

  play() {
    if (this.videoTarget.dataset.active == 1) {
        return false
    }
    
    console.log('Playing video')
    let embedData = this.videoTarget.dataset.embed
    console.log(embedData)
    this.videoTarget.innerHTML = embedData
    this.videoTarget.dataset.active = 1;
  }
  
})